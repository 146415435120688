.p-carousel-container {
  @apply relative;
}
.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  @apply  p-[0.375rem] rounded-full bg-white border border-gray-200  z-10;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.p-carousel .p-carousel-content .p-carousel-next {
  @apply absolute right-4;
}
.p-carousel .p-carousel-content .p-carousel-prev {
  @apply absolute left-4;
}
