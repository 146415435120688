.p-toast .p-toast-message {
  &.p-toast-message-success,
  &.p-toast-message-error {
    @apply border border-solid rounded-xl;
  }
  &.p-toast-message-success {
    @apply border-success-300 bg-success-25 text-success-700;

    .p-toast-message-icon {
      @apply text-2xl font-semibold;
      .sctr-icon-message-check-circle::before {
        @apply text-success-700;
      }
    }

    .p-toast-icon-close {
      @apply text-success-500;
    }
  }
  &.p-toast-message-error {
    @apply border-error-300 bg-error-25 text-error-700;

    .p-toast-message-icon {
      @apply text-2xl font-semibold;
      .sctr-icon-x-circle::before {
        @apply text-error-700;
      }
    }

    .p-toast-icon-close {
      @apply text-error-700;
    }
  }

  .p-toast-message-content {
    @apply items-center border-0;

    .p-toast-summary {
      @apply text-md font-semibold;
    }

    .p-toast-detail {
      @apply hidden;
    }
  }
}

.t-show-toast-detail .p-toast .p-toast-message {
  .p-toast-message-content .p-toast-detail {
    @apply block;
  }
}
