@import 'styles/sanitize.scss';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

// Variables
// @import 'styles/spacing.scss';
// @import 'styles/typography.scss';
// @import 'styles/utility.scss';
// Fonts
// @import 'styles/font.scss';
// Icons
// @import 'styles/icon.scss';
// Themes
@import 'styles/theme/light/light.scss';
@import 'styles/theme/dark/dark.scss';
// Component
@import 'styles/component/photo-frame.scss';
@import 'styles/component/budget.scss';
@import 'styles/component/slider.scss';
@import 'styles/component/confirm-dialog.scss';
@import 'styles/component/chip.scss';
@import 'styles/component/overlay-panel.scss';
@import 'styles/component/toast.scss';
@import 'styles/font.scss';
@import 'primeicons/primeicons.css';
@import 'styles/component/tooltip.scss';
/* SCSS partials */
@import 'theme/scss/partials/_custom-flags.scss';
:root {
  color-scheme: light dark;
  font-size: 16px;
  font-family: Inter, Amiri, Helvetica, Arial, sans-serif;
  line-height: var(--text-line-height-lg);
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  // Set light as default theme
  --color-scheme-base-1: var(--light-color-scheme-base-1);
  --color-scheme-base-2: var(--light-color-scheme-base-2);
  --color-scheme-gray-25: var(--light-color-scheme-gray-25);
  --color-scheme-gray-50: var(--light-color-scheme-gray-50);
  --color-scheme-gray-100: var(--light-color-scheme-gray-100);
  --color-scheme-gray-200: var(--light-color-scheme-gray-200);
  --color-scheme-gray-300: var(--light-color-scheme-gray-300);
  --color-scheme-gray-400: var(--light-color-scheme-gray-400);
  --color-scheme-gray-500: var(--light-color-scheme-gray-500);
  --color-scheme-gray-600: var(--light-color-scheme-gray-600);
  --color-scheme-gray-700: var(--light-color-scheme-gray-700);
  --color-scheme-gray-800: var(--light-color-scheme-gray-800);
  --color-scheme-gray-900: var(--light-color-scheme-gray-900);
  --color-scheme-primary-25: var(--light-color-scheme-primary-25);
  --color-scheme-primary-50: var(--light-color-scheme-primary-50);
  --color-scheme-primary-100: var(--light-color-scheme-primary-100);
  --color-scheme-primary-200: var(--light-color-scheme-primary-200);
  --color-scheme-primary-300: var(--light-color-scheme-primary-300);
  --color-scheme-primary-400: var(--light-color-scheme-primary-400);
  --color-scheme-primary-500: var(--light-color-scheme-primary-500);
  --color-scheme-primary-600: var(--light-color-scheme-primary-600);
  --color-scheme-primary-700: var(--light-color-scheme-primary-700);
  --color-scheme-primary-800: var(--light-color-scheme-primary-800);
  --color-scheme-primary-900: var(--light-color-scheme-primary-900);
  --color-scheme-error-25: var(--light-color-scheme-error-25);
  --color-scheme-error-50: var(--light-color-scheme-error-50);
  --color-scheme-error-100: var(--light-color-scheme-error-100);
  --color-scheme-error-200: var(--light-color-scheme-error-200);
  --color-scheme-error-300: var(--light-color-scheme-error-300);
  --color-scheme-error-400: var(--light-color-scheme-error-400);
  --color-scheme-error-500: var(--light-color-scheme-error-500);
  --color-scheme-error-600: var(--light-color-scheme-error-600);
  --color-scheme-error-700: var(--light-color-scheme-error-700);
  --color-scheme-error-800: var(--light-color-scheme-error-800);
  --color-scheme-error-900: var(--light-color-scheme-error-900);
  --color-scheme-warning-25: var(--light-color-scheme-warning-25);
  --color-scheme-warning-50: var(--light-color-scheme-warning-50);
  --color-scheme-warning-100: var(--light-color-scheme-warning-100);
  --color-scheme-warning-200: var(--light-color-scheme-warning-200);
  --color-scheme-warning-300: var(--light-color-scheme-warning-300);
  --color-scheme-warning-400: var(--light-color-scheme-warning-400);
  --color-scheme-warning-500: var(--light-color-scheme-warning-500);
  --color-scheme-warning-600: var(--light-color-scheme-warning-600);
  --color-scheme-warning-700: var(--light-color-scheme-warning-700);
  --color-scheme-warning-800: var(--light-color-scheme-warning-800);
  --color-scheme-warning-900: var(--light-color-scheme-warning-900);
  --color-scheme-success-25: var(--light-color-scheme-success-25);
  --color-scheme-success-50: var(--light-color-scheme-success-50);
  --color-scheme-success-100: var(--light-color-scheme-success-100);
  --color-scheme-success-200: var(--light-color-scheme-success-200);
  --color-scheme-success-300: var(--light-color-scheme-success-300);
  --color-scheme-success-400: var(--light-color-scheme-success-400);
  --color-scheme-success-500: var(--light-color-scheme-success-500);
  --color-scheme-success-600: var(--light-color-scheme-success-600);
  --color-scheme-success-700: var(--light-color-scheme-success-700);
  --color-scheme-success-800: var(--light-color-scheme-success-800);
  --color-scheme-success-900: var(--light-color-scheme-success-900);
  --color-scheme-moss-25: var(--light-color-scheme-moss-25);
  --color-scheme-moss-50: var(--light-color-scheme-moss-50);
  --color-scheme-moss-100: var(--light-color-scheme-moss-100);
  --color-scheme-moss-200: var(--light-color-scheme-moss-200);
  --color-scheme-moss-300: var(--light-color-scheme-moss-300);
  --color-scheme-moss-400: var(--light-color-scheme-moss-400);
  --color-scheme-moss-500: var(--light-color-scheme-moss-500);
  --color-scheme-moss-600: var(--light-color-scheme-moss-600);
  --color-scheme-moss-700: var(--light-color-scheme-moss-700);
  --color-scheme-moss-800: var(--light-color-scheme-moss-800);
  --color-scheme-moss-900: var(--light-color-scheme-moss-900);
  --color-scheme-green-light-25: var(--light-color-scheme-green-light-25);
  --color-scheme-green-light-50: var(--light-color-scheme-green-light-50);
  --color-scheme-green-light-100: var(--light-color-scheme-green-light-100);
  --color-scheme-green-light-200: var(--light-color-scheme-green-light-200);
  --color-scheme-green-light-300: var(--light-color-scheme-green-light-300);
  --color-scheme-green-light-400: var(--light-color-scheme-green-light-400);
  --color-scheme-green-light-500: var(--light-color-scheme-green-light-500);
  --color-scheme-green-light-600: var(--light-color-scheme-green-light-600);
  --color-scheme-green-light-700: var(--light-color-scheme-green-light-700);
  --color-scheme-green-light-800: var(--light-color-scheme-green-light-800);
  --color-scheme-green-light-900: var(--light-color-scheme-green-light-900);
  --color-scheme-green-25: var(--light-color-scheme-green-25);
  --color-scheme-green-50: var(--light-color-scheme-green-50);
  --color-scheme-green-100: var(--light-color-scheme-green-100);
  --color-scheme-green-200: var(--light-color-scheme-green-200);
  --color-scheme-green-300: var(--light-color-scheme-green-300);
  --color-scheme-green-400: var(--light-color-scheme-green-400);
  --color-scheme-green-500: var(--light-color-scheme-green-500);
  --color-scheme-green-600: var(--light-color-scheme-green-600);
  --color-scheme-green-700: var(--light-color-scheme-green-700);
  --color-scheme-green-800: var(--light-color-scheme-green-800);
  --color-scheme-green-900: var(--light-color-scheme-green-900);
  --color-scheme-teal-25: var(--light-color-scheme-teal-25);
  --color-scheme-teal-50: var(--light-color-scheme-teal-50);
  --color-scheme-teal-100: var(--light-color-scheme-teal-100);
  --color-scheme-teal-200: var(--light-color-scheme-teal-200);
  --color-scheme-teal-300: var(--light-color-scheme-teal-300);
  --color-scheme-teal-400: var(--light-color-scheme-teal-400);
  --color-scheme-teal-500: var(--light-color-scheme-teal-500);
  --color-scheme-teal-600: var(--light-color-scheme-teal-600);
  --color-scheme-teal-700: var(--light-color-scheme-teal-700);
  --color-scheme-teal-800: var(--light-color-scheme-teal-800);
  --color-scheme-teal-900: var(--light-color-scheme-teal-900);
  --color-scheme-cyan-25: var(--light-color-scheme-cyan-25);
  --color-scheme-cyan-50: var(--light-color-scheme-cyan-50);
  --color-scheme-cyan-100: var(--light-color-scheme-cyan-100);
  --color-scheme-cyan-200: var(--light-color-scheme-cyan-200);
  --color-scheme-cyan-300: var(--light-color-scheme-cyan-300);
  --color-scheme-cyan-400: var(--light-color-scheme-cyan-400);
  --color-scheme-cyan-500: var(--light-color-scheme-cyan-500);
  --color-scheme-cyan-600: var(--light-color-scheme-cyan-600);
  --color-scheme-cyan-700: var(--light-color-scheme-cyan-700);
  --color-scheme-cyan-800: var(--light-color-scheme-cyan-800);
  --color-scheme-cyan-900: var(--light-color-scheme-cyan-900);
  --color-scheme-blue-light-25: var(--light-color-scheme-blue-light-25);
  --color-scheme-blue-light-50: var(--light-color-scheme-blue-light-50);
  --color-scheme-blue-light-100: var(--light-color-scheme-blue-light-100);
  --color-scheme-blue-light-200: var(--light-color-scheme-blue-light-200);
  --color-scheme-blue-light-300: var(--light-color-scheme-blue-light-300);
  --color-scheme-blue-light-400: var(--light-color-scheme-blue-light-400);
  --color-scheme-blue-light-500: var(--light-color-scheme-blue-light-500);
  --color-scheme-blue-light-600: var(--light-color-scheme-blue-light-600);
  --color-scheme-blue-light-700: var(--light-color-scheme-blue-light-700);
  --color-scheme-blue-light-800: var(--light-color-scheme-blue-light-800);
  --color-scheme-blue-light-900: var(--light-color-scheme-blue-light-900);
  --color-scheme-blue-25: var(--light-color-scheme-blue-25);
  --color-scheme-blue-50: var(--light-color-scheme-blue-50);
  --color-scheme-blue-100: var(--light-color-scheme-blue-100);
  --color-scheme-blue-200: var(--light-color-scheme-blue-200);
  --color-scheme-blue-300: var(--light-color-scheme-blue-300);
  --color-scheme-blue-400: var(--light-color-scheme-blue-400);
  --color-scheme-blue-500: var(--light-color-scheme-blue-500);
  --color-scheme-blue-600: var(--light-color-scheme-blue-600);
  --color-scheme-blue-700: var(--light-color-scheme-blue-700);
  --color-scheme-blue-800: var(--light-color-scheme-blue-800);
  --color-scheme-blue-900: var(--light-color-scheme-blue-900);
  --color-scheme-blue-dark-25: var(--light-color-scheme-blue-dark-25);
  --color-scheme-blue-dark-50: var(--light-color-scheme-blue-dark-50);
  --color-scheme-blue-dark-100: var(--light-color-scheme-blue-dark-100);
  --color-scheme-blue-dark-200: var(--light-color-scheme-blue-dark-200);
  --color-scheme-blue-dark-300: var(--light-color-scheme-blue-dark-300);
  --color-scheme-blue-dark-400: var(--light-color-scheme-blue-dark-400);
  --color-scheme-blue-dark-500: var(--light-color-scheme-blue-dark-500);
  --color-scheme-blue-dark-600: var(--light-color-scheme-blue-dark-600);
  --color-scheme-blue-dark-700: var(--light-color-scheme-blue-dark-700);
  --color-scheme-blue-dark-800: var(--light-color-scheme-blue-dark-800);
  --color-scheme-blue-dark-900: var(--light-color-scheme-blue-dark-900);
  --color-scheme-indigo-25: var(--light-color-scheme-indigo-25);
  --color-scheme-indigo-50: var(--light-color-scheme-indigo-50);
  --color-scheme-indigo-100: var(--light-color-scheme-indigo-100);
  --color-scheme-indigo-200: var(--light-color-scheme-indigo-200);
  --color-scheme-indigo-300: var(--light-color-scheme-indigo-300);
  --color-scheme-indigo-400: var(--light-color-scheme-indigo-400);
  --color-scheme-indigo-500: var(--light-color-scheme-indigo-500);
  --color-scheme-indigo-600: var(--light-color-scheme-indigo-600);
  --color-scheme-indigo-700: var(--light-color-scheme-indigo-700);
  --color-scheme-indigo-800: var(--light-color-scheme-indigo-800);
  --color-scheme-indigo-900: var(--light-color-scheme-indigo-900);
  --color-scheme-violet-25: var(--light-color-scheme-violet-25);
  --color-scheme-violet-50: var(--light-color-scheme-violet-50);
  --color-scheme-violet-100: var(--light-color-scheme-violet-100);
  --color-scheme-violet-200: var(--light-color-scheme-violet-200);
  --color-scheme-violet-300: var(--light-color-scheme-violet-300);
  --color-scheme-violet-400: var(--light-color-scheme-violet-400);
  --color-scheme-violet-500: var(--light-color-scheme-violet-500);
  --color-scheme-violet-600: var(--light-color-scheme-violet-600);
  --color-scheme-violet-700: var(--light-color-scheme-violet-700);
  --color-scheme-violet-800: var(--light-color-scheme-violet-800);
  --color-scheme-violet-900: var(--light-color-scheme-violet-900);
  --color-scheme-purple-25: var(--light-color-scheme-purple-25);
  --color-scheme-purple-50: var(--light-color-scheme-purple-50);
  --color-scheme-purple-100: var(--light-color-scheme-purple-100);
  --color-scheme-purple-200: var(--light-color-scheme-purple-200);
  --color-scheme-purple-300: var(--light-color-scheme-purple-300);
  --color-scheme-purple-400: var(--light-color-scheme-purple-400);
  --color-scheme-purple-500: var(--light-color-scheme-purple-500);
  --color-scheme-purple-600: var(--light-color-scheme-purple-600);
  --color-scheme-purple-700: var(--light-color-scheme-purple-700);
  --color-scheme-purple-800: var(--light-color-scheme-purple-800);
  --color-scheme-purple-900: var(--light-color-scheme-purple-900);
  --color-scheme-fuchsia-25: var(--light-color-scheme-fuchsia-25);
  --color-scheme-fuchsia-50: var(--light-color-scheme-fuchsia-50);
  --color-scheme-fuchsia-100: var(--light-color-scheme-fuchsia-100);
  --color-scheme-fuchsia-200: var(--light-color-scheme-fuchsia-200);
  --color-scheme-fuchsia-300: var(--light-color-scheme-fuchsia-300);
  --color-scheme-fuchsia-400: var(--light-color-scheme-fuchsia-400);
  --color-scheme-fuchsia-500: var(--light-color-scheme-fuchsia-500);
  --color-scheme-fuchsia-600: var(--light-color-scheme-fuchsia-600);
  --color-scheme-fuchsia-700: var(--light-color-scheme-fuchsia-700);
  --color-scheme-fuchsia-800: var(--light-color-scheme-fuchsia-800);
  --color-scheme-fuchsia-900: var(--light-color-scheme-fuchsia-900);
  --color-scheme-pink-25: var(--light-color-scheme-pink-25);
  --color-scheme-pink-50: var(--light-color-scheme-pink-50);
  --color-scheme-pink-100: var(--light-color-scheme-pink-100);
  --color-scheme-pink-200: var(--light-color-scheme-pink-200);
  --color-scheme-pink-300: var(--light-color-scheme-pink-300);
  --color-scheme-pink-400: var(--light-color-scheme-pink-400);
  --color-scheme-pink-500: var(--light-color-scheme-pink-500);
  --color-scheme-pink-600: var(--light-color-scheme-pink-600);
  --color-scheme-pink-700: var(--light-color-scheme-pink-700);
  --color-scheme-pink-800: var(--light-color-scheme-pink-800);
  --color-scheme-pink-900: var(--light-color-scheme-pink-900);
  --color-scheme-rose-25: var(--light-color-scheme-rose-25);
  --color-scheme-rose-50: var(--light-color-scheme-rose-50);
  --color-scheme-rose-100: var(--light-color-scheme-rose-100);
  --color-scheme-rose-200: var(--light-color-scheme-rose-200);
  --color-scheme-rose-300: var(--light-color-scheme-rose-300);
  --color-scheme-rose-400: var(--light-color-scheme-rose-400);
  --color-scheme-rose-500: var(--light-color-scheme-rose-500);
  --color-scheme-rose-600: var(--light-color-scheme-rose-600);
  --color-scheme-rose-700: var(--light-color-scheme-rose-700);
  --color-scheme-rose-800: var(--light-color-scheme-rose-800);
  --color-scheme-rose-900: var(--light-color-scheme-rose-900);
  --color-scheme-orange-dark-25: var(--light-color-scheme-orange-dark-25);
  --color-scheme-orange-dark-50: var(--light-color-scheme-orange-dark-50);
  --color-scheme-orange-dark-100: var(--light-color-scheme-orange-dark-100);
  --color-scheme-orange-dark-200: var(--light-color-scheme-orange-dark-200);
  --color-scheme-orange-dark-300: var(--light-color-scheme-orange-dark-300);
  --color-scheme-orange-dark-400: var(--light-color-scheme-orange-dark-400);
  --color-scheme-orange-dark-500: var(--light-color-scheme-orange-dark-500);
  --color-scheme-orange-dark-600: var(--light-color-scheme-orange-dark-600);
  --color-scheme-orange-dark-700: var(--light-color-scheme-orange-dark-700);
  --color-scheme-orange-dark-800: var(--light-color-scheme-orange-dark-800);
  --color-scheme-orange-dark-900: var(--light-color-scheme-orange-dark-900);
  --color-scheme-orange-25: var(--light-color-scheme-orange-25);
  --color-scheme-orange-50: var(--light-color-scheme-orange-50);
  --color-scheme-orange-100: var(--light-color-scheme-orange-100);
  --color-scheme-orange-200: var(--light-color-scheme-orange-200);
  --color-scheme-orange-300: var(--light-color-scheme-orange-300);
  --color-scheme-orange-400: var(--light-color-scheme-orange-400);
  --color-scheme-orange-500: var(--light-color-scheme-orange-500);
  --color-scheme-orange-600: var(--light-color-scheme-orange-600);
  --color-scheme-orange-700: var(--light-color-scheme-orange-700);
  --color-scheme-orange-800: var(--light-color-scheme-orange-800);
  --color-scheme-orange-900: var(--light-color-scheme-orange-900);
  --color-scheme-yellow-25: var(--light-color-scheme-yellow-25);
  --color-scheme-yellow-50: var(--light-color-scheme-yellow-50);
  --color-scheme-yellow-100: var(--light-color-scheme-yellow-100);
  --color-scheme-yellow-200: var(--light-color-scheme-yellow-200);
  --color-scheme-yellow-300: var(--light-color-scheme-yellow-300);
  --color-scheme-yellow-400: var(--light-color-scheme-yellow-400);
  --color-scheme-yellow-500: var(--light-color-scheme-yellow-500);
  --color-scheme-yellow-600: var(--light-color-scheme-yellow-600);
  --color-scheme-yellow-700: var(--light-color-scheme-yellow-700);
  --color-scheme-yellow-800: var(--light-color-scheme-yellow-800);
  --color-scheme-yellow-900: var(--light-color-scheme-yellow-900);
}

body,
body .p-component {
  @apply font-Inter;
}

// Styles for tour detail overview
.t-more-categories-panel {
  .p-overlaypanel-content {
    @apply p-2 shadow-lg max-w-xs flex flex-row flex-wrap gap-2;
  }
}

.t-rich-text {
  .ql-editor {
    padding: 0px;
  }
  a {
    color: #06c;
    text-decoration: underline;
  }

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-display-xs;
  }

  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }

  .mention {
    color: #06c;
    text-decoration: underline;
  }
}

.t-toast .p-toast {
  @apply top-[92px];
  .p-element .ng-trigger {
    @apply w-[400px] min-h-[52px] m-0 gap-3 pt-0;
    .p-toast-message-icon {
      @apply h-5 w-5 leading-5;
    }
    .p-toast-detail {
      @apply m-0;
    }
    .p-toast-icon-close {
      @apply h-5 w-5;
    }
  }
}
