.t-confirm-dialog {
  .p-dialog {
    @apply rounded-xl w-full md:w-[500px] md:max-w-[43%];

    .p-dialog-header {
      @apply pt-6 pb-1 rounded-t-xl;

      .p-dialog-title {
        @apply text-2xl;
      }
    }

    .p-dialog-content {
      @apply pb-0;

      .p-confirm-dialog-message {
        @apply m-0;
      }
    }

    .p-dialog-footer {
      @apply p-6 pt-8 rounded-b-xl;

      .p-confirm-dialog-reject {
        @apply bg-white border border-solid border-gray-500 text-gray-500;
      }

      .p-confirm-dialog-accept {
        @apply bg-blue-600 text-white;
      }
    }
  }
}
