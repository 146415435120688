.t-budget {
  @apply flex items-center justify-between py-2 border-b border-solid border-gray-200;
  // border-color: var(--color-scheme-gray-200);
  &-location {
    @apply text-sm font-medium text-gray-700;
    // color: var(--color-scheme-gray-700);
  }
  &-schedule {
    @apply text-xs font-medium text-gray-600;
    // color: var(--color-scheme-gray-600);
  }
  &-currency-symbol {
    @apply underline text-sm font-bold text-gray-800;
    // color: var(--color-scheme-gray-800);
  }
  &-currency-value {
    @apply text-lg ml-0.5 font-semibold text-gray-800;
    // color: var(--color-scheme-gray-800);
  }
}
