:root {
  --light-color-scheme-base-1: #ffffff;
  --light-color-scheme-base-2: #000000;
  --light-color-scheme-gray-25: #fcfcfd;
  --light-color-scheme-gray-50: #f9fafb;
  --light-color-scheme-gray-100: #f2f4f7;
  --light-color-scheme-gray-200: #eaecf0;
  --light-color-scheme-gray-300: #d0d5dd;
  --light-color-scheme-gray-400: #98a2b3;
  --light-color-scheme-gray-500: #667085;
  --light-color-scheme-gray-600: #475467;
  --light-color-scheme-gray-700: #344054;
  --light-color-scheme-gray-800: #1d2939;
  --light-color-scheme-gray-900: #101828;
  --light-color-scheme-primary-25: #f5faff;
  --light-color-scheme-primary-50: #eff8ff;
  --light-color-scheme-primary-100: #d1e9ff;
  --light-color-scheme-primary-200: #b2ddff;
  --light-color-scheme-primary-300: #84caff;
  --light-color-scheme-primary-400: #53b1fd;
  --light-color-scheme-primary-500: #2e90fa;
  --light-color-scheme-primary-600: #1570ef;
  --light-color-scheme-primary-700: #175cd3;
  --light-color-scheme-primary-800: #1849a9;
  --light-color-scheme-primary-900: #194185;
  --light-color-scheme-error-25: #fffbfa;
  --light-color-scheme-error-50: #fef3f2;
  --light-color-scheme-error-100: #fee4e2;
  --light-color-scheme-error-200: #fecdca;
  --light-color-scheme-error-300: #fda29b;
  --light-color-scheme-error-400: #f97066;
  --light-color-scheme-error-500: #f04438;
  --light-color-scheme-error-600: #d92d20;
  --light-color-scheme-error-700: #b42318;
  --light-color-scheme-error-800: #912018;
  --light-color-scheme-error-900: #7a271a;
  --light-color-scheme-warning-25: #fffcf5;
  --light-color-scheme-warning-50: #fffaeb;
  --light-color-scheme-warning-100: #fef0c7;
  --light-color-scheme-warning-200: #fedf89;
  --light-color-scheme-warning-300: #fec84b;
  --light-color-scheme-warning-400: #fdb022;
  --light-color-scheme-warning-500: #f79009;
  --light-color-scheme-warning-600: #dc6803;
  --light-color-scheme-warning-700: #b54708;
  --light-color-scheme-warning-800: #93370d;
  --light-color-scheme-warning-900: #7a2e0e;
  --light-color-scheme-success-25: #f6fef9;
  --light-color-scheme-success-50: #ecfdf3;
  --light-color-scheme-success-100: #d1fadf;
  --light-color-scheme-success-200: #a6f4c5;
  --light-color-scheme-success-300: #6ce9a6;
  --light-color-scheme-success-400: #32d583;
  --light-color-scheme-success-500: #12b76a;
  --light-color-scheme-success-600: #039855;
  --light-color-scheme-success-700: #027a48;
  --light-color-scheme-success-800: #05603a;
  --light-color-scheme-success-900: #054f31;
  --light-color-scheme-moss-25: #fafdf7;
  --light-color-scheme-moss-50: #f5fbee;
  --light-color-scheme-moss-100: #e6f4d7;
  --light-color-scheme-moss-200: #ceeab0;
  --light-color-scheme-moss-300: #acdc79;
  --light-color-scheme-moss-400: #86cb3c;
  --light-color-scheme-moss-500: #669f2a;
  --light-color-scheme-moss-600: #4f7a21;
  --light-color-scheme-moss-700: #3f621a;
  --light-color-scheme-moss-800: #335015;
  --light-color-scheme-moss-900: #2b4212;
  --light-color-scheme-green-light-25: #fafef5;
  --light-color-scheme-green-light-50: #f3fee7;
  --light-color-scheme-green-light-100: #e3fbcc;
  --light-color-scheme-green-light-200: #d0f8ab;
  --light-color-scheme-green-light-300: #a6ef67;
  --light-color-scheme-green-light-400: #85e13a;
  --light-color-scheme-green-light-500: #66c61c;
  --light-color-scheme-green-light-600: #4ca30d;
  --light-color-scheme-green-light-700: #3b7c0f;
  --light-color-scheme-green-light-800: #326212;
  --light-color-scheme-green-light-900: #2b5314;
  --light-color-scheme-green-25: #f6fef9;
  --light-color-scheme-green-50: #edfcf2;
  --light-color-scheme-green-100: #d3f8df;
  --light-color-scheme-green-200: #aaf0c4;
  --light-color-scheme-green-300: #73e2a3;
  --light-color-scheme-green-400: #3ccb7f;
  --light-color-scheme-green-500: #16b364;
  --light-color-scheme-green-600: #099250;
  --light-color-scheme-green-700: #087443;
  --light-color-scheme-green-800: #095c37;
  --light-color-scheme-green-900: #084c2e;
  --light-color-scheme-teal-25: #f6fefc;
  --light-color-scheme-teal-50: #f0fdf9;
  --light-color-scheme-teal-100: #ccfbef;
  --light-color-scheme-teal-200: #99f6e0;
  --light-color-scheme-teal-300: #5fe9d0;
  --light-color-scheme-teal-400: #2ed3b7;
  --light-color-scheme-teal-500: #15b79e;
  --light-color-scheme-teal-600: #0e9384;
  --light-color-scheme-teal-700: #107569;
  --light-color-scheme-teal-800: #125d56;
  --light-color-scheme-teal-900: #134e48;
  --light-color-scheme-cyan-25: #f5feff;
  --light-color-scheme-cyan-50: #ecfdff;
  --light-color-scheme-cyan-100: #cff9fe;
  --light-color-scheme-cyan-200: #a5f0fc;
  --light-color-scheme-cyan-300: #67e3f9;
  --light-color-scheme-cyan-400: #22ccee;
  --light-color-scheme-cyan-500: #06aed4;
  --light-color-scheme-cyan-600: #088ab2;
  --light-color-scheme-cyan-700: #0e7090;
  --light-color-scheme-cyan-800: #155b75;
  --light-color-scheme-cyan-900: #164c63;
  --light-color-scheme-blue-light-25: #f5fbff;
  --light-color-scheme-blue-light-50: #f0f9ff;
  --light-color-scheme-blue-light-100: #e0f2fe;
  --light-color-scheme-blue-light-200: #b9e6fe;
  --light-color-scheme-blue-light-300: #7cd4fd;
  --light-color-scheme-blue-light-400: #36bffa;
  --light-color-scheme-blue-light-500: #0ba5ec;
  --light-color-scheme-blue-light-600: #0086c9;
  --light-color-scheme-blue-light-700: #026aa2;
  --light-color-scheme-blue-light-800: #065986;
  --light-color-scheme-blue-light-900: #0b4a6f;
  --light-color-scheme-blue-25: #f5faff;
  --light-color-scheme-blue-50: #eff8ff;
  --light-color-scheme-blue-100: #d1e9ff;
  --light-color-scheme-blue-200: #b2ddff;
  --light-color-scheme-blue-300: #84caff;
  --light-color-scheme-blue-400: #53b1fd;
  --light-color-scheme-blue-500: #2e90fa;
  --light-color-scheme-blue-600: #1570ef;
  --light-color-scheme-blue-700: #175cd3;
  --light-color-scheme-blue-800: #1849a9;
  --light-color-scheme-blue-900: #194185;
  --light-color-scheme-blue-dark-25: #f5f8ff;
  --light-color-scheme-blue-dark-50: #eff4ff;
  --light-color-scheme-blue-dark-100: #d1e0ff;
  --light-color-scheme-blue-dark-200: #b2ccff;
  --light-color-scheme-blue-dark-300: #84adff;
  --light-color-scheme-blue-dark-400: #528bff;
  --light-color-scheme-blue-dark-500: #2970ff;
  --light-color-scheme-blue-dark-600: #155eef;
  --light-color-scheme-blue-dark-700: #004eeb;
  --light-color-scheme-blue-dark-800: #0040c1;
  --light-color-scheme-blue-dark-900: #00359e;
  --light-color-scheme-indigo-25: #f5f8ff;
  --light-color-scheme-indigo-50: #eef4ff;
  --light-color-scheme-indigo-100: #e0eaff;
  --light-color-scheme-indigo-200: #c7d7fe;
  --light-color-scheme-indigo-300: #a4bcfd;
  --light-color-scheme-indigo-400: #8098f9;
  --light-color-scheme-indigo-500: #6172f3;
  --light-color-scheme-indigo-600: #444ce7;
  --light-color-scheme-indigo-700: #3538cd;
  --light-color-scheme-indigo-800: #2d31a6;
  --light-color-scheme-indigo-900: #2d3282;
  --light-color-scheme-violet-25: #fbfaff;
  --light-color-scheme-violet-50: #f5f3ff;
  --light-color-scheme-violet-100: #ece9fe;
  --light-color-scheme-violet-200: #ddd6fe;
  --light-color-scheme-violet-300: #c3b5fd;
  --light-color-scheme-violet-400: #a48afb;
  --light-color-scheme-violet-500: #875bf7;
  --light-color-scheme-violet-600: #7839ee;
  --light-color-scheme-violet-700: #6927da;
  --light-color-scheme-violet-800: #5720b7;
  --light-color-scheme-violet-900: #491c96;
  --light-color-scheme-purple-25: #fafaff;
  --light-color-scheme-purple-50: #f4f3ff;
  --light-color-scheme-purple-100: #ebe9fe;
  --light-color-scheme-purple-200: #d9d6fe;
  --light-color-scheme-purple-300: #bdb4fe;
  --light-color-scheme-purple-400: #9b8afb;
  --light-color-scheme-purple-500: #7a5af8;
  --light-color-scheme-purple-600: #6938ef;
  --light-color-scheme-purple-700: #5925dc;
  --light-color-scheme-purple-800: #4a1fb8;
  --light-color-scheme-purple-900: #3e1c96;
  --light-color-scheme-fuchsia-25: #fefaff;
  --light-color-scheme-fuchsia-50: #fdf4ff;
  --light-color-scheme-fuchsia-100: #fbe8ff;
  --light-color-scheme-fuchsia-200: #f6d0fe;
  --light-color-scheme-fuchsia-300: #eeaafd;
  --light-color-scheme-fuchsia-400: #e478fa;
  --light-color-scheme-fuchsia-500: #d444f1;
  --light-color-scheme-fuchsia-600: #ba24d5;
  --light-color-scheme-fuchsia-700: #9f1ab1;
  --light-color-scheme-fuchsia-800: #821890;
  --light-color-scheme-fuchsia-900: #6f1877;
  --light-color-scheme-pink-25: #fef6fb;
  --light-color-scheme-pink-50: #fdf2fa;
  --light-color-scheme-pink-100: #fce7f6;
  --light-color-scheme-pink-200: #fcceee;
  --light-color-scheme-pink-300: #faa7e0;
  --light-color-scheme-pink-400: #f670c7;
  --light-color-scheme-pink-500: #ee46bc;
  --light-color-scheme-pink-600: #dd2590;
  --light-color-scheme-pink-700: #c11574;
  --light-color-scheme-pink-800: #9e165f;
  --light-color-scheme-pink-900: #851651;
  --light-color-scheme-rose-25: #fff5f6;
  --light-color-scheme-rose-50: #fff1f3;
  --light-color-scheme-rose-100: #ffe4e8;
  --light-color-scheme-rose-200: #fecdd6;
  --light-color-scheme-rose-300: #fea3b4;
  --light-color-scheme-rose-400: #fd6f8e;
  --light-color-scheme-rose-500: #f63d68;
  --light-color-scheme-rose-600: #e31b54;
  --light-color-scheme-rose-700: #c01048;
  --light-color-scheme-rose-800: #a11043;
  --light-color-scheme-rose-900: #89123e;
  --light-color-scheme-orange-dark-25: #fff9f5;
  --light-color-scheme-orange-dark-50: #fff4ed;
  --light-color-scheme-orange-dark-100: #ffe6d5;
  --light-color-scheme-orange-dark-200: #ffd6ae;
  --light-color-scheme-orange-dark-300: #ff9c66;
  --light-color-scheme-orange-dark-400: #ff692e;
  --light-color-scheme-orange-dark-500: #ff4405;
  --light-color-scheme-orange-dark-600: #e62e05;
  --light-color-scheme-orange-dark-700: #bc1b06;
  --light-color-scheme-orange-dark-800: #97180c;
  --light-color-scheme-orange-dark-900: #771a0d;
  --light-color-scheme-orange-25: #fefaf5;
  --light-color-scheme-orange-50: #fef6ee;
  --light-color-scheme-orange-100: #fdead7;
  --light-color-scheme-orange-200: #f9dbaf;
  --light-color-scheme-orange-300: #f7b27a;
  --light-color-scheme-orange-400: #f38744;
  --light-color-scheme-orange-500: #ef6820;
  --light-color-scheme-orange-600: #e04f16;
  --light-color-scheme-orange-700: #b93815;
  --light-color-scheme-orange-800: #932f19;
  --light-color-scheme-orange-900: #772917;
  --light-color-scheme-yellow-25: #fefdf0;
  --light-color-scheme-yellow-50: #fefbe8;
  --light-color-scheme-yellow-100: #fef7c3;
  --light-color-scheme-yellow-200: #feee95;
  --light-color-scheme-yellow-300: #fde272;
  --light-color-scheme-yellow-400: #fac515;
  --light-color-scheme-yellow-500: #eaaa08;
  --light-color-scheme-yellow-600: #ca8504;
  --light-color-scheme-yellow-700: #a15c07;
  --light-color-scheme-yellow-800: #854a0e;
  --light-color-scheme-yellow-900: #713b12;
}
