.chip {
  @apply flex items-center rounded-2xl px-[10px] py-0.5 text-md font-medium cursor-pointer;
}
.t-chip-1 {
  @apply text-rose-700 bg-rose-50;
}
.t-chip-2 {
  @apply text-success-700 bg-success-50;
}
.t-chip-3 {
  @apply text-indigo-700 bg-indigo-50;
}
.t-chip-4 {
  @apply text-blue-dark-700 bg-blue-dark-50;
}
.t-chip-5 {
  @apply text-purple-700 bg-purple-50;
}
