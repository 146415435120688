.p-overlaypanel {
  @apply my-0 #{!important};
  .p-overlaypanel-content {
    @apply px-0 py-1 rounded-lg;
  }

  &.center {
    @apply md:translate-x-[calc(-50%+0.625rem)] md:translate-y-1 translate-x-0 translate-y-1 #{!important};
  }

  &.p-component::after,
  &.p-component::before {
    content: none;
  }

  ::-webkit-scrollbar {
    @apply w-0.5;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-gray-200 rounded-lg;
  }

  .p-menuitem-link:focus {
    @apply shadow-none;
  }

  .p-menuitem.p-menuitem-active > .p-menuitem-link {
    @apply bg-gray-200;
  }

  .p-menuitem-link:not(.p-disabled):hover {
    @apply bg-gray-100;
  }

  .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
  .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
  .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    @apply text-gray-700;
  }
}
