@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src:
    local('Inter-Thin'),
    url('/assets/fonts/Inter/static/Inter-Thin.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src:
    local('Inter-ExtraLight'),
    url('/assets/fonts/Inter/static/Inter-ExtraLight.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src:
    local('Inter-Light'),
    url('/assets/fonts/Inter/static/Inter-Light.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src:
    local('Inter-Regular'),
    url('/assets/fonts/Inter/static/Inter-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400 600;
  src:
    local('Inter-Italic'),
    url('/assets/fonts/Inter/static/Inter-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700 900;
  src:
    local('Inter-Bold-Italic'),
    url('/assets/fonts/Inter/static/Inter-Bold-Italic-700.otf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src:
    local('Inter-Medium'),
    url('/assets/fonts/Inter/static/Inter-Medium.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src:
    local('Inter-Semibold'),
    url('/assets/fonts/Inter/static/Inter-SemiBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src:
    local('Inter-Bold'),
    url('/assets/fonts/Inter/static/Inter-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src:
    local('Inter-ExtraBold'),
    url('/assets/fonts/Inter/static/Inter-ExtraBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src:
    local('Inter-Black'),
    url('/assets/fonts/Inter/static/Inter-Black.ttf') format('opentype');
}
//Amiri font
@font-face {
  font-family: 'Amiri';
  font-style: normal;
  font-weight: 700;
  src:
    local('Amiri-Bold'),
    url('/assets/fonts/Amiri/Amiri-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Amiri';
  font-style: italic;
  font-weight: 700;
  src:
    local('Amiri-BoldItalic'),
    url('/assets/fonts/Amiri/Amiri-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Amiri';
  font-style: italic;
  font-weight: 400;
  src:
    local('Amiri-Italic'),
    url('/assets/fonts/Amiri/Amiri-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Amiri';
  font-style: normal;
  font-weight: 400;
  src:
    local('Amiri-Regular'),
    url('/assets/fonts/Amiri/Amiri-Regular.ttf') format('truetype');
}
